import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
    "input",
    "submitButton",
    "composeForm",
    "composeInput",
    "voiceContainer",
    "templates",
    "scrollToBottom",
    "inbox"
  ]

  static values = {
    url: String,
    page: Number,
    translations: Object
  }

  initialize() {
    super.initialize()

    this.observer = new IntersectionObserver(this.callback.bind(this), {
      threshold: 1,
      rootMargin: "0px"
    })
  }

  attemptSendMessage(e) {
    if(document.querySelectorAll("[data-uploading]").length > 0) {
      e.preventDefault()

      this.showToastErrorMessage(this.translationsValue.attachmentUploading)
      return
    }

    if(document.querySelectorAll("[data-upload-error]").length > 0) {
      e.preventDefault()
      this.showToastErrorMessage(this.translationsValue.attachmentUploadError)

      return
    }

    if (!this.hasLocation && !this.voiceMessage && this.inputTarget.value.trim().length === 0 && document.querySelectorAll("[data-uploaded]").length === 0) {
      e.preventDefault()

      this.addClass(this.inputTarget.parentElement, "border-red-500")
      this.removeClass(this.inputTarget.parentElement, "focus-within:border-tiger", "focus-within:ring-1", "ring-tiger")
    }

    if (e.target !== this.submitButtonTarget) {
      this.submitButtonTarget.click()
      e.preventDefault()
    }
  }

  sync(e) {
    if (
      this.inputTarget.parentElement.classList.contains("border-red-500") &&
      this.inputTarget.value.trim().length !== 0
    ) {
      this.removeClass(this.inputTarget.parentElement, "border-red-500")
      this.addClass(this.inputTarget.parentElement, "focus-within:border-tiger", "focus-within:ring-1", "ring-tiger")
    }

    if (!e.shiftKey && e.key === "Enter") {
      if(this.templatesTarget.hasAttribute('data-visible') && this.inputTarget.value.trim() === '/') {
        e.preventDefault()
        e.stopPropagation()

        this.dispatch('templates:enter', {
          target: this.templatesTarget,
        })

        return
      }

      this.attemptSendMessage(e)
      e.stopImmediatePropagation()
    }
  }

  onKeyUp(e) {
    const value = e.target.textContent

    console.log("value", value)

    if(value.trim().startsWith("/")) {
      this.dispatch("templates:show", {
        target: document.documentElement,
        detail: value.trim().split("/")[1]
      })
    } else {
      this.dispatch("templates:hide", {
        target: document.documentElement,
      })
    }
  }

  focusNote() {
    this.nextTick(() => {
      this.dispatch("note:focus", {
        target: document.documentElement,
      })
    }, 100)
  }

  focusCompose({ detail }) {
    this.dispatch("note:blur", {
      target: document.documentElement,
    })

    const option = document.querySelector(`[data-element-id='${detail}']`)

    if(option) {
      option.dataset.action = option.dataset.action.split(" ").filter(action => action !== "click->custom-dropdown#toggle").join(" ")
      option.click()
      setTimeout(() => {
        option.dataset.action = ["click->custom-dropdown#toggle", ...option.dataset.action.split(" ")].join(" ")
      }, 1)
    } else {

      this.dispatch('focus', {
        target: document.documentElement
      })
    }
  }

  focusMessage({ currentTarget }) {
    const messageContentElement = window.document.getElementById(currentTarget.dataset.messageId)
    this.observer.observe(messageContentElement)
    messageContentElement.scrollIntoView({ behavior: "smooth", block: "nearest" })
  }

  focusCampaignMessage({ detail: messageId }) {
    const messageContentElement = window.document.getElementById(messageId)
    this.observer.observe(messageContentElement)
    messageContentElement.scrollIntoView({ behavior: "smooth", block: "nearest" })
  }

  callback(entries, observer) {
    entries.forEach(entry => {

      if (entry.isIntersecting) {
        const parentElement = entry.target.closest("[data-flashable]")

        if(parentElement.dataset.type === "vcard") {
          this.dispatch("flash-message", {
            target: parentElement,
            detail: { id: parentElement.id }
          })
        } else {
          const chatHead = parentElement.querySelector("[data-message-target='chatHead']")

          const containerClass = parentElement.dataset.messageState === "received" ? "bg-lavender-light" : "bg-night-10"
          const chatHeadClass = parentElement.dataset.messageState === "received" ? "fill-lavender-light" : "fill-night-10"

          entry.target.classList.replace(containerClass, "bg-lavender")

          if(chatHead) {
            chatHead.classList.replace(chatHeadClass, "fill-lavender")
          }

          setTimeout(() => {
            entry.target.classList.replace("bg-lavender", containerClass)

            if(chatHead) {
              chatHead.classList.replace("fill-lavender", chatHeadClass)
            }
          }, 500)
        }


        observer.unobserve(entry.target);
      }
    });
  }

  scrollToBottomTargetConnected() {
    this.inboxTarget.scroll({
      top: this.inboxTarget.scrollHeight,
      behavior: "smooth"
    })

    this.scrollToBottomTarget.remove()
  }

  get hasLocation() {
    return this.locationInputs.filter(location => location.value).length > 0
  }

  get locationInputs() {
    return Array.from(this.element.querySelectorAll("input[data-google-map-target]"))
  }

  get voiceMessage() {
    return this.voiceContainerTarget.children.length > 0
  }
}
